/* eslint-disable eqeqeq, react-hooks/exhaustive-deps, default-case */

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    getContextRoot,
    getLanguageValue,
    navigateToPath,
    getVisaCountriesList,
    getCountriesList,
    getCitiesList,
    scrollToElement,
    getMaritalStatusList,
    getEducationList,
    getProfessionList,
    genericGetListFromLanguage,
    isInactiveField,
    splitArrayIntoChunks, isOneOfSystemProfiles,
    getPurposeOfTravelList,
    getBorderOfEntryList,
    getExtensionByFileContent
} from "../../common/functions";
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import RegisterBottomButtons from "./RegisterBottomButtons";
import CONSTANTS from '../../common/constants';
import { ACTIONS } from '../../redux/actions';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import {CSS_COLORS} from "../../common/cssColors";
import PdfHandler from "../PdfHandler";

function RegisterStage6Component(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    const isViewOnly = window.location.href.indexOf('register-visa-for-individual/view') > -1;
    const [ collapseControls, setCollapseControls ] = useState({ });
    let [ confirmedStages, setConfirmedStages ] = useState({ });
    let [ extraConfirmations, setExtraConfirmations ] = useState({});
    let [ errors, setErrors ] = useState({});
    const stage5QuestionsList = getStage5QuestionsList(lodashGet(props, 'stage5Data'));
    const flightCompanies = getLanguageValue('register-individual.stage4-flight-companies-array').split(',').map((value) => ({ value, displayText: getLanguageValue('register-individual.stage4-flight-company-' + value) }));
    const selectedFlightCompanyDisplayText = lodashGet(flightCompanies.find((item) => item.value == lodashGet(props, 'stage4Data.flightCompany')), 'displayText') || 'ERROR';
    const countriesList = getCountriesList();
    const visaCountriesList = getVisaCountriesList();
    const maritalStatusList = getMaritalStatusList();
    const educationList = getEducationList();
    const professionList = getProfessionList();
    const citiesList = getCitiesList();
    const purposeOfTravelList = getPurposeOfTravelList();
    const borderOfEntryList = getBorderOfEntryList();
    let stage2Fields = [
        { key: 'firstName', description: getLanguageValue('register-individual.stage2-first-name'), render: function(value) { return value; } },
        { key: 'lastName', description: getLanguageValue('register-individual.stage2-family-name'), render: function(value) { return value; } },
        { key: 'firstNameEnglish', description: getLanguageValue('register-individual.stage2-first-name-english'), render: function(value) { return value; } },
        { key: 'lastNameEnglish', description: getLanguageValue('register-individual.stage2-family-name-english'), render: function(value) { return value; } },
        { key: 'motherName', description: getLanguageValue('register-individual.stage2-mother-name'), render: function(value) { return value; } },
        { key: 'fatherName', description: getLanguageValue('register-individual.stage2-father-name'), render: function(value) { return value; } },
        { key: 'gender', description: getLanguageValue('register-individual.stage2-gender'), render: function(value) { return getLanguageValue('register-individual.stage2-gender-' + value); } },
        { key: 'birthDate', description: getLanguageValue('register-individual.stage2-date-of-birth'), render: function(value) { return moment(value).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
        //{ key: 'cityOfBirth', description: getLanguageValue('register-individual.stage2-city-of-birth'), render: function(value) {
        //  if (lodashGet(props, 'stage2Data.countryOfBirth') == 'Israel') {
        //        return lodashGet(citiesList.find((item) => item.key == value), 'value');
        //  }
        //    return value;
      //  } },
        { key: 'countryOfBirth', description: getLanguageValue('register-individual.stage2-country-of-birth'), render: function(value) { return lodashGet(countriesList.find((item) => item.key == value), 'value'); } },
        { key: 'passportNumber', description: getLanguageValue('register-individual.stage2-passport-number'), render: function(value) { return value; } },
        { key: 'countryOfCitizenship', description: getLanguageValue('register-individual.stage2-country-of-citizenship'), render: function(value) { return lodashGet(visaCountriesList.find((item) => item.key == value), 'value'); } },
        { key: 'issuanceDate', description: getLanguageValue('register-individual.stage2-issuance-date'), render: function(value) { return moment(value).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
        { key: 'expirationDate', description: getLanguageValue('register-individual.stage2-expiration-date'), render: function(value) { return moment(value).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
        { key: 'issuingCountry', description: getLanguageValue('register-individual.stage2-issuing-country'), render: function(value) { return lodashGet(countriesList.find((item) => item.key == value), 'value'); } },
      //  { key: 'issuingCity', description: getLanguageValue('register-individual.stage2-issuing-city'), render: function(value) {
      //      let matchViaListsResult = lodashGet(citiesList.concat(genericGetListFromLanguage('register-individual.stage2-issuing-cities-extras-keys', 'register-individual.stage2-issuing-cities-extras-values', 'key', 'value')).find((item) => item.key == value), 'value');
      //      return matchViaListsResult || value;
      //  } },
       //{ key: 'personalIdentificationNumber', description: getLanguageValue('register-individual.stage2-personal-identification-number'), render: function(value) { return value; } },
       //{ key: 'flightCountryOrigin', description: getLanguageValue('register-individual.stage2-flight-country-origin'), render: function(value) { return lodashGet(countriesList.find((item) => item.key == value), 'value'); } },
        { key: 'purposeOfTravel', description: getLanguageValue('register-individual.stage2-select-purpose-of-travel'), render: function(value) { return lodashGet(purposeOfTravelList.find((item) => item.key == value), 'value'); } }
      //  { key: 'borderOfEntry', description: getLanguageValue('register-individual.stage2-select-border-of-entry'), render: function(value) { return lodashGet(borderOfEntryList.find((item) => item.key == value), 'value'); } }
    ];
    // filter by plain rules
    stage2Fields = stage2Fields.filter((fieldItem) => !isInactiveField('stage6', fieldItem.key));
    if (props.languageKey != 'he') {
        // unique - filter hebrew fields
        stage2Fields = stage2Fields.filter((fieldItem) => (['firstName', 'lastName'].indexOf(fieldItem.key) == -1));
    }

    let stage2Chunks = splitArrayIntoChunks(stage2Fields, 4);
    let stage3Fields = [
    //    { key: 'addressLine1', description: getLanguageValue('register-individual.stage3-address-line1'), render: function(value) { return value; } },
    //    { key: 'addressLine2', description: getLanguageValue('register-individual.stage3-address-line2'), render: function(value) { return value; } },
  //      { key: 'apartmentNumber', description: getLanguageValue('register-individual.stage3-apartment-number'), render: function(value) { return value; } },
  //      { key: 'city', description: getLanguageValue('register-individual.stage3-city'), render: function(value) { return value; } },
  //      { key: 'stateOrProvince', description: getLanguageValue('register-individual.stage3-state-or-province'), render: function(value) { return value; } },
//       { key: 'country', description: getLanguageValue('register-individual.stage3-country'), render: function(value) { return lodashGet(countriesList.find((item) => item.key == value), 'value'); } },
        { key: 'maritalStatus', description: getLanguageValue('register-individual.stage3-marital-status'), render: function(value) { return lodashGet(maritalStatusList.find((item) => item.key == value), 'value'); } },
        { key: 'education', description: getLanguageValue('register-individual.stage3-education'), render: function(value) { return lodashGet(educationList.find((item) => item.key == value), 'value'); } },
        { key: 'profession', description: getLanguageValue('register-individual.stage3-profession'), render: function(value) { return lodashGet(professionList.find((item) => item.key == value), 'value'); } }
    ];
    // filter by plain rules
    stage3Fields = stage3Fields.filter((fieldItem) => !isInactiveField('stage6', fieldItem.key));
    let stage3Chunks = splitArrayIntoChunks(stage3Fields, 4);

    useEffect(() => {
        actions.setActiveStage(!isViewOnly ? 6 : false);
        window.routeChangeTimestamp = new Date().getTime();
    }, []);
    const isMinor = moment().diff(moment(lodashGet(props, 'stage2Data.birthDate')), 'years') < 18;
    let stage2ImageList = [
        {
            key: 'passport',
            isDisplayed: function() {
                return !isInactiveField('stage6', 'passport');
            },
            text: getLanguageValue('register-individual.stage6-passport-image'),
            additionalHtmlText: getLanguageValue('register-individual.stage6-image-general-warning'),
            sampleImageUrl: getContextRoot() + 'images/sample_passport.jpg',
            focusQueryParam: 'uploaded-image-passport'
        },
        {
            key: 'passportFace',
            isDisplayed: function() {
                return !isInactiveField('stage6', 'passportFace');
            },
            text: getLanguageValue('register-individual.stage6-passport-face-image'),
            additionalHtmlText: getLanguageValue('register-individual.stage2-upload-passport-face-note'),
            sampleImageUrl: getContextRoot() + 'images/sample_passport_face.jpg',
            focusQueryParam: 'uploaded-image-passport-face'
        },
        {
            key: 'personalIdWithAppendix',
            isDisplayed: function() {
                return !isInactiveField('stage6', 'personalIdWithAppendix');
            },
            text: getLanguageValue('register-individual.stage2-upload-personal-id-with-appendix'),
            additionalHtmlText: false,
            sampleImageUrl: false,
            focusQueryParam: 'uploaded-image-personal-id-with-appendix'
        },
        {
            key: 'healthGreenCardOrVaccination',
            isDisplayed: function() {
                return !isInactiveField('stage6', 'healthGreenCardOrVaccination');
            },
            text: getLanguageValue('register-individual.stage2-upload-health-green-card-or-vaccination'),
            additionalHtmlText: false,
            sampleImageUrl: false,
            focusQueryParam: 'uploaded-image-health-green-card-or-vaccination'
        },
        {
            key: 'passportBackside',
            isDisplayed: function() {
                return !isInactiveField('stage6', 'passportBackside') && (lodashGet(props, 'stage2Data.countryOfCitizenship') || '').toLowerCase() == 'india';
            },
            text: getLanguageValue('register-individual.stage6-passport-backside-image'),
            additionalHtmlText: false,
            sampleImageUrl: false,
            focusQueryParam: 'uploaded-image-passport-backside'
        },
        {
            key: 'birthCertificate',
            isDisplayed: function() {
                return isMinor && !isInactiveField('stage6', 'birthCertificate') && CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('birthCertificate') > -1;
            },
            text: getLanguageValue('register-individual.stage6-birth-certificate-image'),
            additionalHtmlText: false,
            sampleImageUrl: false,
            focusQueryParam: 'uploaded-image-birth-certificate'
        },
        {
            key: 'parentPassport',
            isDisplayed: function() {
                return isMinor && !isInactiveField('stage6', 'parentPassport') && CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('parentPassport') > -1;
            },
            text: getLanguageValue('register-individual.stage6-parent-passport-image'),
            additionalHtmlText: false,
            sampleImageUrl: false,
            focusQueryParam: 'uploaded-image-parent-passport'
        }
    ];
    for (let stageImageItem of stage2ImageList) {
        let findResult = props.uploadedFiles.find((item) => item.fileType == stageImageItem.key);
        if (findResult && lodashGet(findResult, 'fileContent')) {
            stageImageItem.fileContent = lodashGet(findResult, 'fileContent');
            if (getExtensionByFileContent(stageImageItem.fileContent) == 'pdf') {
                stageImageItem.showAsPdf = true;
            }
        }
        else {
            stageImageItem.isDisplayed = () => { return false; };
        }
    }

    return <RegisterStage6Style>
        <div className={"form-container"}>
            <h1>{getLanguageValue('register-individual.stage6-review-application')}</h1>
            {!isViewOnly && <div className="form-information-container">
                <span className="bold-text">{getLanguageValue('register-individual.stage6-review-application-explanation')}</span>
            </div>}
            <br />

            <ListItem className={"stage2-header stage-header" + (errors.stage2 == true ? ' error' : '')} onClick={() => { setCollapseControls({ ...collapseControls, stage2: !collapseControls.stage2 }); }}>
                { collapseControls.stage2 ? <ExpandMore /> : <ExpandLess /> }
                { getLanguageValue('register-individual.stage6-applicant-information') }
                { confirmedStages.stage2 == true && <span className="stage-confirmed-component">{ getLanguageValue('register-individual.stage6-stage-confirmed') }</span> }
                { errors.stage2 == true && <span className={"field-error"}>{ getLanguageValue('register-individual.stage6-stage-please-confirm') }</span> }
                {!isViewOnly && <span className={"edit-button-container"}><Button className={"edit-button"} onClick={(e) => { editStage(e, 2); }}><EditIcon/>{getLanguageValue('register-individual.stage6-edit')}</Button></span>}
            </ListItem>
            <Collapse className={"stage-content" + (collapseControls.stage2 ? ' opened' : ' closed')} in={collapseControls.stage2} timeout={0}>
                <div className="stage-content-container">
                    {stage2Chunks.map((chunkArray, chunkArrayIndex) => {
                        return <Grid key={chunkArrayIndex} className={"fields-row-container" + (stage2Chunks.length == (chunkArrayIndex + 1) ? ' last' : '')} container>{chunkArray.map((fieldItem, fieldItemIndex) => {
                            return <Grid key={chunkArrayIndex + '_' + fieldItemIndex} item data-key={fieldItem.key} className={"field-container"} md={3}>
                                <b>{fieldItem.description}</b>
                                <br/>
                                {fieldItem.render(lodashGet(props, `stage2Data.${fieldItem.key}`))}
                            </Grid>;
                        })}</Grid>;
                    })}

                    <hr className={"separator"} />
                    <h3>{getLanguageValue('register-individual.stage2-select-visa-type')}</h3>
                    {!isInactiveField('stage6', 'visaType') && <span className={"visa-type-container"}><b>{getLanguageValue('register-individual.stage2-select-visa-duration')}</b> - {getLanguageValue('register-individual.stage2-select-visa-type-' + lodashGet(props, 'stage2Data.visaType'))}</span>}
                    {!isInactiveField('stage6', 'visaExpedited') && <><br/><span className={"visa-expedited-container"}><b>{getLanguageValue('register-individual.stage2-expedite-visa')}</b> - {getLanguageValue(lodashGet(props, 'stage2Data.visaExpeditedFlag') ? 'register-individual.stage2-yes' : 'register-individual.stage2-no')}</span></>}
                    {!isInactiveField('stage6', 'arrivalDate') && <><br/><span><b>{getLanguageValue('register-individual.stage2-arrival-date')}</b> - {moment(lodashGet(props, 'stage2Data.arrivalDate')).format(CONSTANTS.DATE_FORMAT_MOMENT)}</span></>}
                    <br/><br/>

                    {!isInactiveField('stage6', 'deliveryType') && !isFormLinkedToGroup() && <>
                        <h3>{getLanguageValue('register-individual.stage2-select-visa-delivery-type')}</h3>
                        {!isInactiveField('stage6', 'deliveryType') && <>
                            <span className={"visa-delivery-type-container"}><b>{getLanguageValue('register-individual.stage2-select-visa-delivery-type-' + lodashGet(props, 'stage2Data.deliveryType'))}</b></span>
                            <div className={'delivery-self-address-container'} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-select-visa-delivery-self-address') }} />
                            <div className={'delivery-self-address-container'} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-select-visa-delivery-activity-time') }} />
                        </>}
                        {!isInactiveField('stage6', 'deliveryDestination') && <><br/><span className={"visa-delivery-destination-container"}><b>{getLanguageValue('register-individual.stage2-select-visa-delivery-destination')}</b> - {CONSTANTS?.PRICES?.delivery.find((item) => item.key == lodashGet(props, 'stage2Data.deliveryDestination'))?.displayText}</span></>}
                        <br/>
                        <br/>
                    </>}

                    {stage2ImageList.map((imageItem, imageItemIndex) => (imageItem.isDisplayed() && <React.Fragment key={imageItemIndex}>
                        <div>
                            <b>{imageItem.text}</b>
                            {!!imageItem.additionalHtmlText && <div className={"image-warning-text field-warning-color"} dangerouslySetInnerHTML={{ __html: imageItem.additionalHtmlText }} />}
                        </div>
                        <div>
                            {imageItem.showAsPdf ?
                                <PdfHandler base64Data={imageItem.fileContent} className={'passport'} controls={{}} /> :
                                <img className={`client-pictures-component ${imageItem.key}`} src={imageItem.fileContent} alt={""} />}
                        </div>
                        <br/>
                        {!isViewOnly && <div><Button className={`edit-image-button ${imageItem.key}`} onClick={() => { editImage('stage2', imageItem.key); }}>{getLanguageValue('crop-handler-edit')}</Button></div>}
                        <br/>
                        {!!imageItem.sampleImageUrl && <div>
                            <img className={`sample-image ${imageItem.key}`} src={imageItem.sampleImageUrl} alt={""} />
                        </div>}
                        <br/>
                    </React.Fragment>))}
                    {false && <>
                        <hr className={"separator"} />
                        <h3>{getLanguageValue('register-individual.stage2-other-citizenship-or-nationality')}</h3>
                        <span className={"citizen-of-any-other-country-question"}><b>{getLanguageValue('register-individual.stage2-are-you-a-citizen-of-any-other-country')}</b> - {getLanguageValue(lodashGet(props, 'stage2Data.questionAreYouACitizenOfOtherCountry') == 'yes' ? 'register-individual.stage2-yes' : 'register-individual.stage2-no')}</span>
                        {lodashGet(props, 'stage2Data.questionAreYouACitizenOfOtherCountry') == 'yes' && <><table className={"citizen-of-other-country-table table-component"}>
                            <thead>
                                <tr className={"citizen-of-other-country-table-header table-header-component"}>
                                    <td>{getLanguageValue('register-individual.stage2-country-of-citizenship-or-nationality')}</td>
                                    <td>{getLanguageValue('register-individual.stage2-how-citizenship-received')}</td>
                                    <td>{getLanguageValue('register-individual.stage2-citizenship-received-other')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.stage2Data.currentlyCitizenOfCountries.map(function(item, itemIndex) {
                                        return <tr key={itemIndex}>
                                            <td>
                                                {lodashGet(countriesList.find((item) => item.key == item.countryOfCitizenship), 'value')}
                                            </td>
                                            <td>
                                                {translateHowCitizenshipReceived(item.howCitizenshipReceived)}
                                            </td>
                                            <td>
                                                {item.other}
                                            </td>
                                        </tr>;
                                    })
                                }
                            </tbody>
                        </table></>}
                        <br />
                        <span className={"citizen-of-any-other-country-question"}><b>{getLanguageValue('register-individual.stage2-have-you-been-a-citizen-of-any-other-country')}</b> - {getLanguageValue(lodashGet(props, 'stage2Data.questionWereYouACitizenOfOtherCountry') == 'yes' ? 'register-individual.stage2-yes' : 'register-individual.stage2-no')}</span>
                        {lodashGet(props, 'stage2Data.questionWereYouACitizenOfOtherCountry') == 'yes' && <table className={"citizen-of-other-country-table table-component"}>
                            <thead>
                            <tr className={"citizen-of-other-country-table-header table-header-component"}>
                                <td>{getLanguageValue('register-individual.stage2-country-of-citizenship-or-nationality')}</td>
                                <td>{getLanguageValue('register-individual.stage2-citizenship-start-date-without-format')}</td>
                                <td>{getLanguageValue('register-individual.stage2-citizenship-end-date-without-format')}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                props.stage2Data.previousCitizenOfCountries.map(function(item, itemIndex) {
                                    return <tr key={itemIndex}>
                                        <td>
                                            {lodashGet(countriesList.find((item) => item.key == item.countryOfCitizenship), 'value')}
                                        </td>
                                        <td>
                                            {moment(item.startDate).format(CONSTANTS.DATE_FORMAT_MOMENT)}
                                        </td>
                                        <td>
                                            {moment(item.endDate).format(CONSTANTS.DATE_FORMAT_MOMENT)}
                                        </td>
                                    </tr>;
                                })
                            }
                            </tbody>
                        </table>}
                    </>}
                    <hr className={"separator"} />
                    <b>{ getLanguageValue('register-individual.stage2-email-address') }</b>
                    <br/>
                    {lodashGet(props, 'stage2Data.email')}
                    {!isViewOnly && confirmedStages.stage2 != true && <BottomConfirmContainer stage={'stage2'} error={!!errors.stage2} />}
                </div>
            </Collapse>

            <ListItem className={"stage3-header stage-header" + (errors.stage3 == true ? ' error' : '')} onClick={() => { if (!isStage3Valid()) return; setCollapseControls({ ...collapseControls, stage3: !collapseControls.stage3 }); }}>
                {isStage3Valid() ? <>{ collapseControls.stage3 ? <ExpandMore /> : <ExpandLess /> }</> : <span className={"unfilled-yet-text field-warning-color"}>{'(' + getLanguageValue('register-individual.stage6-stage-unfilled') + ')'} &nbsp;</span> }
                { getLanguageValue('register-individual.stage6-personal-information') }
                {confirmedStages.stage3 == true && <span className="stage-confirmed-component">{ getLanguageValue('register-individual.stage6-stage-confirmed') }</span>}
                { errors.stage3 == true && <span className={"field-error"}>{ getLanguageValue('register-individual.stage6-stage-please-confirm') }</span> }
                {!isViewOnly && <span className={"edit-button-container"}><Button className={"edit-button"} onClick={(e) => { editStage(e, 3); }}><EditIcon/>{getLanguageValue('register-individual.stage6-edit')}</Button></span>}
            </ListItem>
            <Collapse className={"stage-content" + (collapseControls.stage3 ? ' opened' : ' closed')} in={collapseControls.stage3} timeout={0}>
                <div className="stage-content-container">
                    {stage3Chunks.map((chunkArray, chunkArrayIndex) => {
                        return <Grid key={chunkArrayIndex} className={"fields-row-container" + (chunkArray.length == chunkArrayIndex + 1 ? ' last' : '')} container>{chunkArray.map((fieldItem, fieldItemIndex) => {
                            return <Grid key={chunkArrayIndex + '_' + fieldItemIndex} item className={"field-container"} md={3}>
                                <b>{fieldItem.description}</b>
                                <br/>
                                {fieldItem.render(lodashGet(props, `stage3Data.${fieldItem.key}`))}
                            </Grid>;
                        })}</Grid>;
                    })}
                    <div className={"stage3-phones-container"}>
                        <b>{getLanguageValue('register-individual.stage6-phones')}</b>
                        <table className={"stage3-phones-table table-component"}>
                            <thead>
                            <tr className={"stage3-phones-table-header table-header-component"}>
                                <td>{getLanguageValue('register-individual.stage3-phone-type')}</td>
                                <td>{getLanguageValue('register-individual.stage3-phone-country-code')}</td>
                                <td>{getLanguageValue('register-individual.stage3-phone-number')}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (lodashGet(props, 'stage3Data.phones') || []).map(function(item, itemIndex) {
                                    return <tr key={itemIndex}>
                                        <td>
                                            {translateTelephoneType(item.telephoneType)}
                                        </td>
                                        <td className={"stage3-country-code-value"}>
                                            {item.countryCode}
                                        </td>
                                        <td>
                                            {item.phoneNumber}
                                        </td>
                                    </tr>;
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    {!isViewOnly &&confirmedStages.stage3 != true && <BottomConfirmContainer stage={'stage3'} error={!!errors.stage3} />}
                </div>
            </Collapse>

            {false && <ListItem className={"stage4-header stage-header" + (errors.stage4 == true ? ' error' : '')} onClick={() => { if (!isStage4Valid()) return; setCollapseControls({ ...collapseControls, stage4: !collapseControls.stage4 }); }}>
                { isStage4Valid() ? <>{collapseControls.stage4 ? <ExpandMore /> : <ExpandLess />}</> : <span className={"unfilled-yet-text field-warning-color"}>{'(' + getLanguageValue('register-individual.stage6-stage-unfilled') + ')'} &nbsp;</span> }
                { getLanguageValue('register-individual.stage6-travel-information') }
                {confirmedStages.stage4 == true && <span className="stage-confirmed-component">{ getLanguageValue('register-individual.stage6-stage-confirmed') }</span>}
                { errors.stage4 == true && <span className={"field-error"}>{ getLanguageValue('register-individual.stage6-stage-please-confirm') }</span> }
                {!isViewOnly && <span className={"edit-button-container"}><Button className={"edit-button"} onClick={(e) => { editStage(e, 4); }}><EditIcon/>{getLanguageValue('register-individual.stage6-edit')}</Button></span>}
            </ListItem>}
            {false && <Collapse className={"stage-content" + (collapseControls.stage4 ? ' opened' : ' closed')} in={collapseControls.stage4} timeout={0}>
                <div className="stage-content-container">
                    <h3><u>{getLanguageValue('register-individual.stage4-flight-information')}</u></h3>
                    <Grid className={"fields-row-container"} container>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-flight-date') }</b>
                            <br/>
                            {moment(lodashGet(props, 'stage4Data.flightDate')).format(CONSTANTS.DATE_FORMAT_MOMENT)}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-flight-number') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.flightNumber')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-flight-company') }</b>
                            <br/>
                            {selectedFlightCompanyDisplayText}
                        </Grid>
                    </Grid>
                    <h3><u>{getLanguageValue('register-individual.stage4-flight-destination-information')}</u></h3>
                    <Grid className={"fields-row-container"} container>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-hotel-name') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.flightDestinationHotelName')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-hotel-address') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.flightDestinationHotelAddress')}
                        </Grid>
                    </Grid>
                    <h3><u>{getLanguageValue('register-individual.stage4-contact-information')}</u></h3>
                    <Grid className={"fields-row-container"} container>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-contact-name') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.contactName')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-address-line1') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.contactAddressLine1')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-address-line2') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.contactAddressLine2')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-apartment-number') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.contactApartmentNumber')}
                        </Grid>
                    </Grid>
                    <Grid className={"fields-row-container"} container>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-city') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.contactCity')}
                        </Grid>
                    </Grid>
                    <h3><u>{getLanguageValue('register-individual.stage4-address-while-in-country')}</u></h3>
                    <Grid className={"fields-row-container"} container>
                        {!lodashGet(props, 'stage4Data.addressWhileInCountryIsLikeHotelAddressFlag') ?
                            <>
                                <Grid item className={"field-container"} md={3}>
                                    <b>{ getLanguageValue('register-individual.stage4-address-line1') }</b>
                                    <br/>
                                    {lodashGet(props, lodashGet(props, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactAddressLine1' : 'stage4Data.addressLine1')}
                                </Grid>
                                <Grid item className={"field-container"} md={3}>
                                    <b>{ getLanguageValue('register-individual.stage4-address-line2') }</b>
                                    <br/>
                                    {lodashGet(props, lodashGet(props, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactAddressLine2' : 'stage4Data.addressLine2')}
                                </Grid>
                                <Grid item className={"field-container"} md={3}>
                                    <b>{ getLanguageValue('register-individual.stage4-apartment-number') }</b>
                                    <br/>
                                    {lodashGet(props, lodashGet(props, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactApartmentNumber' : 'stage4Data.apartmentNumber')}
                                </Grid>
                                <Grid item className={"field-container"} md={3}>
                                    <b>{ getLanguageValue('register-individual.stage4-city') }</b>
                                    <br/>
                                    {lodashGet(props, lodashGet(props, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactCity' : 'stage4Data.city')}
                                </Grid>
                            </> : <Grid item className={"field-container"} md={3}>
                                {lodashGet(props, 'stage4Data.flightDestinationHotelAddress')}
                            </Grid>}

                    </Grid>
                    <h3><u>{getLanguageValue('register-individual.stage4-contact-in-case-of-emergency')}</u></h3>
                    <Grid className={"fields-row-container"} container>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-emergency-contact-first-name') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.emergencyContactFirstName')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-emergency-contact-last-name') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.emergencyContactLastName')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-emergency-contact-email') }</b>
                            <br/>
                            {lodashGet(props, 'stage4Data.emergencyContactEmail')}
                        </Grid>
                        <Grid item className={"field-container"} md={3}>
                            <b>{ getLanguageValue('register-individual.stage4-emergency-contact-phone-country-code') } + { getLanguageValue('register-individual.stage4-emergency-contact-phone-number') }</b>
                            <br/>
                            <span className={"number-value"}>({lodashGet(props, 'stage4Data.emergencyContactPhoneCountryCode')}) {lodashGet(props, 'stage4Data.emergencyContactPhoneNumber')}</span>
                        </Grid>
                    </Grid>
                    {!isViewOnly &&confirmedStages.stage4 != true && <BottomConfirmContainer stage={'stage4'} error={!!errors.stage4} />}
                </div>
            </Collapse>}

            <ListItem className={"stage5-header stage-header" + (errors.stage5 == true ? ' error' : '')} onClick={() => { if (!isStage5Valid()) return; setCollapseControls({ ...collapseControls, stage5: !collapseControls.stage5 }); }}>
                { isStage5Valid() ? <>{collapseControls.stage5 ? <ExpandMore /> : <ExpandLess />}</> : <span className={"unfilled-yet-text field-warning-color"}>{'(' + getLanguageValue('register-individual.stage6-stage-unfilled') + ')'} &nbsp;</span> }
                { getLanguageValue('register-individual.stage6-personal-questions') }
                {confirmedStages.stage5 == true && <span className="stage-confirmed-component">{ getLanguageValue('register-individual.stage6-stage-confirmed') }</span>}
                { errors.stage5 == true && <span className={"field-error"}>{ getLanguageValue('register-individual.stage6-stage-please-confirm') }</span> }
                {!isViewOnly && <span className={"edit-button-container"}><Button className={"edit-button"} onClick={(e) => { editStage(e, 5); }}><EditIcon/>{getLanguageValue('register-individual.stage6-edit')}</Button></span>}
            </ListItem>
            <Collapse className={"stage-content" + (collapseControls.stage5 ? ' opened' : ' closed')} in={collapseControls.stage5} timeout={0}>
                <div className={"stage-content-container"}>
                    {stage5QuestionsList.map((questionItem, questionItemIndex) => (
                        <Grid key={questionItemIndex} className={"question-and-input-container question-key-" + questionItem.key}>
                            <div dangerouslySetInnerHTML={{ __html: questionItem.value }} />
                            <div className={"answer-container"}>
                                {questionItem.answer == 'yes' ? getLanguageValue('register-individual.stage5-answer-yes') : getLanguageValue('register-individual.stage5-answer-no')}
                            </div>
                        </Grid>
                    ))}
                    {!isViewOnly &&confirmedStages.stage5 != true && <>
                        {isOneOfSystemProfiles(['cambodevisa']) && <div className={'confirmation-of-passport-with-pictures'}>
                            <div className={'confirm-passport-with-pictures-text'}>
                                <span className={!!extraConfirmations['passportWithPictures'] ? 'confirm-of-passport-with-pictures-ok' : ''}>{getLanguageValue('register-individual.stage6-confirm-of-passport-with-pictures')}</span>
                                {!!extraConfirmations['passportWithPictures'] && <span className={'confirm-of-passport-with-pictures-ok'}><b>&nbsp;({getLanguageValue('register-individual.stage6-stage-confirmed')})</b></span>}
                                {!extraConfirmations['passportWithPictures'] && <>&nbsp;<Button onClick={() => { setExtraConfirmations({...extraConfirmations, passportWithPictures: true}); }} className={'confirmation-of-passport-with-pictures-button'}>{getLanguageValue('register-individual.stage6-stage-confirm')}</Button></>}
                            </div>
                        </div>}
                        <BottomConfirmContainer stage={'stage5'} extraConfirmation={!!extraConfirmations['passportWithPictures']} error={!!errors.stage5} />
                    </>}
                </div>
            </Collapse>

            {!isViewOnly && <RegisterBottomButtons
                actions={{
                    loaders: {},
                    isNextDisabled,
                    next,
                    back
                }}
            />}
            {isViewOnly && <div className={"go-back-button-container"}>
                <Button className="go-back-button" onClick={() => { window.NAVIGATION_WARNING_ENABLED = false; window.history.back(); }}>{getLanguageValue('register-common.stages-buttons-go-back')}</Button>
            </div>}
        </div>
    </RegisterStage6Style>;

    function translateHowCitizenshipReceived(value) {
        if (value == 'birth') {
            return getLanguageValue('register-individual.stage2-citizenship-received-by-birth');
        }
        if (value == 'parents') {
            return getLanguageValue('register-individual.stage2-citizenship-received-through-parents');
        }
        if (value == 'naturalized') {
            return getLanguageValue('register-individual.stage2-citizenship-received-naturalized');
        }
        return getLanguageValue('register-individual.stage2-citizenship-received-other');
    }

    function translateTelephoneType(value) {
        if (value == 'mobile') {
            return getLanguageValue('register-individual.stage3-phone-type-mobile');
        }
        if (value == 'work') {
            return getLanguageValue('register-individual.stage3-phone-type-work');
        }
        return getLanguageValue('register-individual.stage3-phone-type-home');
    }

    function isStage3Valid() {

        return (lodashGet(props.stage3Data, 'education') || '').length > 0;
    }

    function isStage4Valid() {
        return (lodashGet(props.stage4Data, 'contactCity') || '').length > 0;
    }

    function isStage5Valid() {
        return lodashGet(props.stage5Data, 'questionsAndAnswers') != null;
    }

    function getStage5QuestionsList(stage5Data) {
        let existingQuestionsAndAnswers = {};
        if (lodashGet(stage5Data, 'questionsAndAnswers')) {
            // save mapping of key / answer to set in next code
            for (let questionKey in stage5Data.questionsAndAnswers) {
                existingQuestionsAndAnswers[ questionKey ] = stage5Data.questionsAndAnswers[questionKey];
            }
        }

        const output = [];
        const questionsKeysArray = getLanguageValue('register-individual.stage5-questions-array').replace(/\s/g, '').split(',');
        for (let questionKeySuffix of questionsKeysArray) {
            const fullKey = 'register-individual.stage5-questions-item-' + questionKeySuffix;
            const questionValue = getLanguageValue(fullKey);
            if (questionValue) {
                output.push({ key: questionKeySuffix, value: questionValue, answer: existingQuestionsAndAnswers[questionKeySuffix] || '' });
            }
        }
        return output;
    }

    function editStage(e, stageNumber) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({
            type: ACTIONS.ROUTER_SET_REDIRECT,
            payload: '/register-visa-for-individual/stage' + stageNumber
        });
    }

    function editImage(stage, imageKey) {
        switch (stage) {
            case 'stage2':
                let matchedImageItem = stage2ImageList.find((item) => item.key == imageKey);
                if (matchedImageItem) {
                    dispatch({
                        type: ACTIONS.ROUTER_SET_REDIRECT,
                        payload: '/register-visa-for-individual/stage2?focusInto=' + matchedImageItem.focusQueryParam
                    });
                }
                break;
        }
    }

    function isNextDisabled() {
        return false;
    }

    async function next() {
        // goto next stage
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (props.isIndividualLinkedToGroup) {
            // go to group stage2
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [
                    {
                        path: 'globalControls.isIndividualLinkedToGroup',
                        value: false
                    },
                    {
                        path: 'globalControls.refreshGroupData',
                        value: true
                    }
                ]
            });
            setTimeout(() => {
                navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group/stage2');
            }, 10);
            return;
        }

        if (actions && actions.nextStage) {
            try {
                actions.nextStage();
            }
            catch (err) {
                // nothing to do
            }
        }
    }

    function back() {
        dispatch({
            type: ACTIONS.ROUTER_SET_REDIRECT,
            payload: '/register-visa-for-individual/stage5'
        });
    }

    function isFormLinkedToGroup() {
        if (props.isIndividualLinkedToGroup) {
            return true;
        }
        if (Array.isArray(props.registerGroupStage2Data?.linkedIndividualApplications)) {
            for (let item of props.registerGroupStage2Data?.linkedIndividualApplications) {
                if (props.individualKey == item.key) {
                    return true;
                }
            }
        }
        return false;
    }

    function BottomConfirmContainer(props) {
        return <div className={"bottom-confirm-container"}>
            <Button id={'confirm-button-' + props.stage} className={"confirm-and-continue-button" + (props.error ? ' button-error' : '')} onClick={() => {
                if (props.extraConfirmation === false) {
                    // wait for extra confirmation before confirming stage
                    return;
                }
                confirmedStages[props.stage] = true;
                setConfirmedStages({...confirmedStages, [props.stage] : true });
                let nextUnconfirmedStage = getNextUnconfirmedStrip();
                setCollapseControls({...collapseControls, [props.stage]: false, ...(nextUnconfirmedStage ? { [nextUnconfirmedStage]: true } : {}) });
                setTimeout(() => { scrollToElement('.' + nextUnconfirmedStage + '-header'); }, 10);
                setErrors({ ...errors, [props.stage]: false });
            }}>{ getLanguageValue('register-individual.stage6-stage-confirm-and-continue') }</Button>
            {props.error && <div className={"confirm-button-warning-text"}><br/>{getLanguageValue('register-individual.stage6-stage-please-confirm')}</div>}
        </div>;
    }

    function getNextUnconfirmedStrip() {
        const stagesList = [ 'stage2', 'stage3', 'stage5' ];
        for (let stageItem of stagesList) {
            if (!confirmedStages[stageItem]) {
                return stageItem;
            }
        }
        return false;
    }

    function validate(config) {
        let localErrors = [];
        let firstCollapseItem = '';
        let newCollapseControls = { ...collapseControls };
        if (lodashGet(config, 'setErrors')) {
            errors = {};
        }
        let stagesList = [ 'stage2', 'stage3', 'stage5' ];
        for (let loop = 0 ; loop < stagesList.length ; ++loop) {
            let stageKey = stagesList[loop];
            if (!confirmedStages[stageKey]) {
                localErrors.push(stageKey);
                if (!firstCollapseItem) {
                    firstCollapseItem = stageKey;
                }
                if (lodashGet(config, 'setErrors')) {
                    newCollapseControls[stageKey] = true;
                    errors[stageKey] = true;
                }
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setErrors(errors);
            setCollapseControls({ ...newCollapseControls });
            if (firstCollapseItem) {
                setTimeout(() => {
                    // autofocus
                    if (document.querySelector('.button-error')) {
                        document.querySelector('.button-error').scrollIntoView();
                    }
                }, 150);
            }
        }
        return localErrors;
    }
}

const RegisterStage6Style = styled.div`
padding-top: 20px;
padding-bottom: 20px;
.form-container {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 25px;
}
.stage-header {
  background-color: #f5f5f5;
  border: 1px solid #aaa;
  cursor: pointer;
  height: 60px;
  &.error {
    border: 1px solid #A00000;
  }
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
    height: 90px;
  }
}
.stage-confirmed-component {
  font-weight: bold;
  color: green;
  position: absolute;
  html.rtl & {
    left: 120px;
  }
  html.ltr & {
    right: 120px;
  }
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
    top: 64px;
    html.rtl & {
      left: 30px;
    }
    html.ltr & {
      right: 10px;
    }
  }
}
.edit-button-container {
  position: absolute;
  html.rtl & {
    left: 15px;
  }
  html.ltr & {
    right: 15px;
  }
}
.edit-button {
  border: 1px solid #ddd;
}
.field-error {
  color: #A00000;
  font-weight: bold;
  html.rtl & {
    margin-right: 10px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      position: absolute;
      left: 25px;
      top: 2px;
    }
  }
  html.ltr & {
    margin-left: 10px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      position: absolute;
      right: 6px;
      top: 2px;
    }
  }
}
.stage-content {
  border: 1px solid #aaa;
  border-top: none;
}
.stage-content-container {
  padding: 20px;
}
.image-warning-text {
  max-width: 500px;
}
.sample-image {
  max-width: 100%;
}
.edit-image-button {
  border: 1px solid black;
  &.passport {
    width: 500px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
  &.passportFace {
    width: 350px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
  &.birth-certificate {
    width: 500px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
  &.parent-passport {
    width: 500px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
  &.passport-backside {
    width: 500px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
}
.client-pictures-component {
  border: 1px dashed black;
  &.passport {
    max-width: 500px;
  }
  &.passportFace {
    max-width: 350px;
  }
  &.birth-certificate {
    max-width: 500px;
  }
  &.parent-passport {
    max-width: 500px;
  }
  &.passport-backside {
    max-width: 500px;
  }
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
    max-width: 100% !important;
  }
}
.bottom-confirm-container {
  margin-top: 20px;
}
.confirm-passport-with-pictures-text {
  margin-top: 20px;
  color: ${CSS_COLORS.FIELD_WARNING};
}
.confirm-of-passport-with-pictures-ok {
  color: green;
}
.confirmation-of-passport-with-pictures-button {
  background-color: green;
  color: white;
  font-weight: bold;
  font-size: 20px;
  &:hover {
    background-color: rgba(0,80,0,0.7);
  }
}
.confirm-and-continue-button {
  background-color: green;
  color: white;
  font-weight: bold;
  font-size: 20px;
  &:hover {
    background-color: rgba(0,80,0,0.7);
  }
}
.confirm-button-warning-text {
  color: ${CSS_COLORS.FIELD_WARNING};
}
.field-container {
  width: 100%;
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
    margin-bottom: 20px;
  }
}
.fields-row-container {
  @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    margin-bottom: 20px;
    &.last {
      margin-bottom: 0;
    }
  }
}
.separator {
  margin-top: 20px;
  margin-bottom: 20px;
}
.delivery-self-address-container {
  color: ${CSS_COLORS.FIELD_WARNING};
  margin-bottom: 10px;
}
.citizen-of-any-other-country-question {
  display: inline-block;
  padding-bottom: 5px;
}
.citizen-of-other-country-table {
  td {
    word-break: break-word;
  }
}
.table-component {
  border: 1px solid #aaa;
}
.table-header-component {
  background-color: #ddd;
}
.stage3-phones-container {
  margin-top: 20px;
  td {
    word-break: break-word;
  }
}
.stage3-country-code-value {
  direction: ltr;
  text-align: right;
}
.go-back-button-container {
  padding-top: 30px;
  > button {
      font-size: 24px;
      background-color: #1c3764;
      color: white;
      border: 1px solid black;
  }
}
`;

const RegisterStage6 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage2Data: lodashGet(state, 'registerIndividualStagesData.stage2Data'),
        stage3Data: lodashGet(state, 'registerIndividualStagesData.stage3Data'),
        stage4Data: lodashGet(state, 'registerIndividualStagesData.stage4Data'),
        stage5Data: lodashGet(state, 'registerIndividualStagesData.stage5Data'),
        isIndividualLinkedToGroup: lodashGet(state, 'globalControls.isIndividualLinkedToGroup'),
        uploadedFiles: lodashGet(state, 'registerIndividualStagesData.uploadedFiles'),
        registerGroupStage2Data: lodashGet(state, 'registerGroupStagesData.stage2Data')
    }),
    {})(RegisterStage6Component);

export default RegisterStage6;
